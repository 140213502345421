import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"
import Images from "../components/Images"

const AboutUsPage: React.FC = () => (
  <Layout activePage="about-us">
    <SEO title="About Us" />
    <h1 className="page-title">About the Potters</h1>
    <div className="potter-info">
      <div className="potter-info__image-container potter-info__image-container--center">
        <Images image="jothepotter" />
      </div>
      <div className="potter-info__details-container">
        <h2 className="potter-info__name">Jo Rollason</h2>
        <p className="potter-info__body">
          Jo has a BA (Hons) in Ceramics from the West Surrey College of Art
          &amp; Design. Having graduated in 1991, she has remained working with
          clay ever since, alongside working as a florist.
        </p>
        <blockquote className="potter-info__quote">
          I produce a range of domestic ware, predominately table ware, such as
          mugs, bowls, plates and jugs. I also produce some more decorative
          pieces, including clocks and lamps.
          <br />
          My work is decorated using a number of different coloured stoneware
          glazes, and are fired in a gas kiln to 1280°C. I fire in both
          oxidation and reduction, which can give different results from the
          same glazes. I love to experiment and create new glazes, and enjoy the
          variety of colours that can be obtained.
        </blockquote>
      </div>
    </div>
    <div className="potter-info potter-info--reverse">
      <div className="potter-info__image-container potter-info__image-container--right">
        <Images image="markthepotter" />
      </div>
      <div className="potter-info__details-container">
        <h2 className="potter-info__name potter-info__name--right">
          Mark Judd-Cooper
        </h2>
        <p className="potter-info__body potter-info__body--right">
          Mark has a BA (Hons) in Ceramics from the West Surrey College of Art &
          Design.
        </p>
        <blockquote className="potter-info__quote potter-info__quote--right">
          I create sculptural vessels which are inspired by the natural world
          and our experiences within it. I am fascinated by the dynamics of
          spirals, how these appear in nature, decay and corrosion both in the
          organic and man-made world.
          <br />I use a variety of making processes for building and developing
          the form of the vessels and their surfaces. I strongly believe in the
          human contact of the clay, and like to leave an impression of how it's
          made. I have developed an inner spiral shell which is further used to
          increase the qualities of corrosion and decay. I also use
          terra-sigillata slip which I use to simulate the qualities of peeling
          and ageing.
          <br />I have an interest in the fragility of life, and the
          psychological and physical decay to which we are all vulnerable.
          <br />
          My pieces are glazed and fired multiple times to enrich and to develop
          the surface qualities.
        </blockquote>
      </div>
    </div>
  </Layout>
)

export default AboutUsPage
